import { IServicePageResponse } from '../../../api.interface'

export default <IServicePageResponse>{
  serviceType: 'assistant',
  formId: 'DK%20Piccoline',
  title: 'Office Assistant',
  subTitle: 'Office assistants on demand or everyday.',
  icon: '/icons/office_assistant.svg',
  image: '/assets/images/sunrise/office_assistant_supermercat.png',
  theme: 'sunrise-office_assistant',
  sections: [
    {
      sectionId: 'hero',
      label: 'Office Assistant',
      title: 'Office assistants on demand or everyday.',
      description:
        'Often we just need that extra set of hands to help with loading the dishwasher, running errands or just tidying up the office. Look no further. We have them on the fly or on recurring basis.',
      image: '/assets/images/sunrise/office_assistant_supermercat.png',
      icon: '/icons/office-assistant.svg',
      buttonText: 'Request a proposal',
    },
    {
      sectionId: 'options',
      title: 'All hands on deck',
      description: '',
      optionsItems: [
        {
          icon: '/assets/images/services/office_assistant/service_1.svg',
          title: 'Day-to-day help',
          description: '',
        },
        {
          icon: '/assets/images/services/office_assistant/service_2.svg',
          title: 'Re-stock of essentials',
          description: '',
        },
        {
          icon: '/assets/images/services/office_assistant/service_3.svg',
          title: 'Run errands - all kinds',
          description: '',
        },
        {
          icon: '/assets/images/services/office_assistant/service_4.svg',
          title: 'Lunch - setup and cleanup',
          description: '',
        },
      ],
    },
    {
      sectionId: 'journey',
      title: 'Get your new office assistant in just a few steps',
      description: '',
      journeyItems: [
        {
          icon: '/assets/images/services/office_assistant/journey_1.svg',
          title: 'Tell us what you need',
          description: 'We are keen to provide you with the perfect setup.',
        },
        {
          icon: '/assets/images/services/office_assistant/journey_2.svg',
          title: 'Receive proposal',
          description: 'We’ll create a detailed proposal that’s easy to approve.',
        },
        {
          icon: '/assets/images/services/office_assistant/journey_3.svg',
          title: 'In-person meeting',
          description: 'Together we will create a customised program for your office.',
        },
        {
          icon: '/assets/images/services/office_assistant/journey_4.svg',
          title: 'Accept!',
          description: 'Moving forward you’ll have an extra set of hands at the office.',
        },
      ],
    },
    {
      sectionId: 'benefits',
      title: 'Why companies love office assistants from Good Monday!',
      description: '',
      image: '/assets/images/services/office_assistant/benefits_illustration.svg',
      benefitsItems: [
        {
          icon: 'eva eva-calendar-outline',
          title: 'Always someone there',
          description:
            'In case of illness or holiday we will always make sure you have an extra set of hands to help out at your office.',
        },
        {
          icon: 'eva eva-pie-chart-outline',
          title: 'Save an FTE',
          description:
            'When having an office assistant through us you don’t have to think about extra employee benifits.',
        },
        {
          icon: 'eva eva-flash-outline',
          title: 'Ready to go',
          description: 'We have trained all the office assistance so they are ready to go from the get-go!',
        },
      ],
    },
    {
      sectionId: 'cta',
      title: 'Did you know that with Good Monday office assistants you’ll never run out of stock again?',
      description: '',
      image: '/assets/images/services/cta_illustration.svg',
      buttonText: 'Request a proposal',
    },
  ],
}
